<template>
  <FullScreenDialog
    v-model="dialog"
    without-padding
    title="Operatori"
    :route-father="routeFather"
  >
    <template v-slot:header-actions>
      <ExportExcelTable
        :worksheetName="worksheetName"
        :fileName="fileName"
        :fieldsToExport="fieldsToExport"
        :fetch="fetchExcel"
      > </ExportExcelTable>
      <v-btn text link @click="goToNew" :disabled="isLicenseBlocked">
        <v-icon class="mr-2">mdi-plus</v-icon> Nuovo
      </v-btn>
    </template>
    <template v-slot:content>
      <div class="d-flex justify-center align-center mb-3" style="width: 100%; height: 15%;">
        <AdvancedFilter
          style="width:99%; height: 100%"
          v-model="filtersValue"
          :filters="filterTypes"
          :advance-filters="advanceFilters"
          :external-filter="externalFilter"
          @apply-filters="applyFilters"
        >
          <template v-slot:chip-custom-textColor="{filter}">
            <div
              class="d-flex justify-center align-center"
              style="gap: 5px"
            >
              <span>Colore del testo: </span>
              <v-avatar
                :color="!!filter.value ? filter.value.hex : 'transparent'"
                class="d-inline-block"
                size="20"
              ></v-avatar>
            </div>
          </template>

          <template v-slot:chip-custom-color="{filter}">
            <div
              class="d-flex justify-center align-center"
              style="gap: 5px"
            >
              <span>Colore dello sfondo: </span>
              <v-avatar
                :color="!!filter.value ? filter.value.hex : 'transparent'"
                class="d-inline-block"
                size="20"
              ></v-avatar>
            </div>
          </template>
          
          <template v-slot:custom-state="{filter}">
            <v-row>
              <v-col>
                <v-select 
                  dense
                  filled
                  v-model="filter.value"
                  :items="[
                    { value: 'valid', text: 'Operatore in forza' },
                    { value: 'disabled', text: 'Operatore cessato' },
                  ]"
                  hide-details="auto"
                  label="Stato*"
                  :menu-props="{ offsetY: true }"
                ></v-select>
              </v-col>
            </v-row>
          </template>
          <template v-slot:custom-qualification="{filter}">
            <v-select
              v-model="filter.value"
              :items="qualificationsOptions"
              label="Qualifica"
              :menu-props="{ offsetY: true }"
              :rules="[presenceRule]"
              filled
              dense
              item-value="id"
              item-text="name"
              hide-details="auto"
              small-chips
              deletable-chips
            ></v-select>
          </template>

          <template v-slot:custom-textColor="{filter}">
            <v-row>
              <v-col 
                class="d-flex justify-center"
                cols="12"
              >
                <v-color-picker
                  :width="!!$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '300px' : '500px'"
                  v-model="filter.value"
                  label="Colore del testo"
                  show-swatches
                ></v-color-picker>
              </v-col>
            </v-row>
          </template>

          <template v-slot:custom-color="{filter}">
            <v-row>
              <v-col 
                class="d-flex justify-center"
                cols="12"
              >
                <v-color-picker
                  :width="!!$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '300px' : '500px'"
                  v-model="filter.value"
                  label="Colore dello sfondo"
                  show-swatches
                ></v-color-picker>
              </v-col>
            </v-row>
          </template>
        </AdvancedFilter>
      </div>
      <div style="height: 83%; overflow-y: hidden" ref="dataTableWrapper">
        <TypeDataTable
          v-model="selected"
          loading-text="Caricamento operatori"
          :headers="headers"
          :items="filteredOperators"
          :loading="loading || loadingHeaders"
          @edit="handleEdit"
          @delete="handleDelete"
          :page.sync="page"
          :row-per-page.sync="rowPerPage"
          :total-pages.sync="totalPages"
          :height="dataTableHeight"
          :translator="translator"
          :show-select="false"
          @dblclick:row="handleEditDoubleClick"
        >
          <template v-slot:custom-qualification="{ item }">
            {{item.qualificationOperators.qualification.name}}
          </template>
        </TypeDataTable>
      </div>
      <StandardDialog
        v-model="showBarcode"
        :title="titleDelete"
        :dialog-height="null"
        dialog-max-width="500px"
        persistent
      >
        <ManualBarcodeInput
          v-model="barcodePermission"
          @confirm="deletePermission"
        >
        </ManualBarcodeInput>
      </StandardDialog>
    </template>
  </FullScreenDialog>
</template>

<script>
import operatorService from '@/services/operators/operators.service.js'
import FullScreenDialog from '@/components/common/FullScreenDialog.vue'
import statesNameMappings from '@/services/operators/statesNameMappings.js'
import TypeDataTable from '@/components/common/TypeDataTable.vue'
import AdvancedFilter from "@/components/common/AdvancedFilter.vue";
import StandardMenu from '@/components/common/StandardMenu.vue'
import ExportExcelTable from "@/components/common/ExportExcelTable.vue";
import StandardDialog from '@/components/common/StandardDialog.vue';
import ManualBarcodeInput from '@/components/common/ManualBarcodeInput.vue';
import qualificationsService from '@/services/qualifications/qualifications.service.js'
import dateUtils from "@/mixins/common/dateUtils"

export default {
  name: "OperatorsRegistryList",
  components: {
    FullScreenDialog,
    TypeDataTable,
    StandardMenu,
    AdvancedFilter,
    ExportExcelTable,
    StandardDialog,
    ManualBarcodeInput,
  },
  mixins:[dateUtils],
  data: function() {
    let props = this.$router.resolve({name: 'Registries'});
    return {
      dialog: true,
      loading: false,
      operators: [],
      routeFather: props.href,
      selected: undefined,
      loadingHeaders: false,
      dataTableHeightValue: 400,
      headers: [],
      page: 1,
      rowPerPage: 100,
      totalPages: 100,
      resizeListener: undefined,
      filterTypes: [
        { type: 'custom', operator: 'equal', field: 'state', name: 'Stato', label: 'Stato', color: "", value: undefined, icon: 'mdi-account-convert-outline' },
        { type: 'custom', operator: 'custom', field: 'qualification', name: 'Qualifica', color: "", value: undefined, icon: 'mdi-clipboard-account' },
        { type: 'custom', operator: 'custom', field: 'textColor', name: 'Colore del testo', color: "", value: undefined, icon: 'mdi-border-color' },
        { type: 'custom', operator: 'custom', field: 'color', name: 'Colore dello sfondo', color: "", value: undefined, icon: 'mdi-format-color-fill'},
        { type: 'number', operator: 'equal', field: 'contractualHours', name: 'Ore Contrattuali', color: "", value: undefined, }
      ],
      advanceFilters: [
      ],
      externalFilter: { type: 'custom', operator: 'custom', field: 'name', name: 'Nome', label: 'Nome', color: "", value: undefined },
      filtersValue: [
      ],
      worksheetName: "",
      fileName:"",
      fieldsToExport: {},
      barcodePermission: undefined,
      showBarcode: false,
      selectedDeleteOperator: undefined,
      qualificationsOptions: [],
      currentUser: undefined,
      isLicenseBlocked: undefined
    }
  },
  props: {
    filters: {
      type: Array,
      default: function() {
        return [
        ]
      }
    }
  },
  mounted: function() {
    this.filtersValue = this.filters

    this.loadOperators()
    this.loadHeaders()

    this.fetchQualifications()

    this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60
    this.resizeListener = function() {
      this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60
    }
    this.resizeListener = this.resizeListener.bind(this)
    window.addEventListener('resize', this.resizeListener)

    this.fieldsToExport = operatorService._fieldsToExport();
    this.worksheetName = "Export Operatori ";
    this.fileName =
      "Operatori_" + this._buildDateToExport(new Date()) +
      ".xls";
  },
  beforeDestroy: function() {
    window.removeEventListener('resize', this.resizeListener)
  },
  methods: {
    loadHeaders() {
      this.loadingHeaders = true
      operatorService.fields().then((headers) => {
        this.headers = headers


        if(!!this.currentUser && this.currentUser.system) {
          this.headers = [
            ...this.headers,
            { text: 'Barcode', value: 'barcode' }
          ]
        }

        this.loadingHeaders = false
      })
    },
    loadOperators() {
      this.loading = true
      operatorService.list(this.page, this.rowPerPage, this.filtersValue).then((results) => {
        if(this.rowPerPage != results.rowPerPage) {
          this.rowPerPage = results.rowPerPage
        }

        if(this.page != results.page) {
          this.page = results.page
        }

        this.totalPages = results.totalPages
        if(this.totalPages < this.page) {
          this.page = this.totalPages
        }
        
        this.operators = results.rows
        this.loading = false
      })
    },
    fetchQualifications() {
      qualificationsService.cachedList().then((results) => {
        this.qualificationsOptions = results
      })
    },
    goToNew() {
      this.$router.push({
        name: 'OperatorsRegistryNewForm', 
        query: {
          pathToGoBack: this.$router.resolve({name: 'OperatorsRegistryList'}).href,
        }
      })
    },
    handleEdit(operator) {
      this.$router.push({
        name: 'OperatorsRegistryEditForm', 
        params: {
          id: operator.id,
          filters: this.filtersValue
        },
        query: {
          pathToGoBack: this.$router.resolve({name: 'OperatorsRegistryList'}).href,
          pathName: 'OperatorsRegistryList',
        }
      })
    },
    handleEditDoubleClick(row, {item: operator}) {
      this.$router.push({
        name: 'OperatorsRegistryEditForm', 
        params: {
          id: operator.id,
          filters: this.filtersValue
        },
        query: {
          pathToGoBack: this.$router.resolve({name: 'OperatorsRegistryList'}).href,
          pathName: 'OperatorsRegistryList'
        }
      })
    },
    deletePermission() {
      operatorService.canPerformOperation(this.barcodePermission, "Registries", "delete").then((result) => {
        if (result) {
          this.loading = true
          operatorService.archive(this.selectedDeleteOperator).then(() => {
            this.loadOperators()
            this.loading = false
          })
        } else {
          alert("Non hai i permessi per eseguire questa azione!")   
        }
      }).catch((error) => {
        console.log(error)
        alert("Badge non riconosciuto")
      })
      this.showBarcode = false
    },
    handleDelete(operator) {
      this.selectedDeleteOperator = operator
      this.barcodePermission = undefined
      this.showBarcode = true
    },
    translator(fieldName, fieldValue) {
      if(fieldName == 'state') {
        return statesNameMappings.getHumanName(fieldValue)
      }
    },
    applyFilters(filters) {
      this.loadOperators()
    },
    async fetchExcel(){
      //let filters = [...this.filtersValue];
      let filters = undefined

      let operators = (await operatorService.list(undefined, 15000, filters)).rows

      return operators
    },
  },
  computed: {
    filteredOperators() {
      return this.operators
    },
    dataTableHeight() {
      return this.dataTableHeightValue + 'px'
    },
    titleDelete() {
      if (this.selectedDeleteOperator)
        return "Elimina " + this.selectedDeleteOperator.firstname + " " + this.selectedDeleteOperator.lastname
      else 
        return "Elimina Operatore"
    },
  },
  watch: {
    page() {
      this.loadOperators()
    },
    rowPerPage() {
      this.loadOperators()
    },
  },
  currentUser: {
    bind: 'currentUser'
  },
  isLicenseBlocked: {
    bind: 'isLicenseBlocked'
  },
}
</script>

<style>

</style>