import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js'
import qualificationsCache from "./qualifications.cache";
import socketService from '@/services/socket/socket.service.js'

class QualificationsService {
  constructor() {
    this._alreadyLoaded = false

    socketService.on('qualifications:create', (data) => {
      this.reloadCache()
    })

    socketService.on('qualifications:update', (data) => {
      this.reloadCache()
    })

    socketService.on('qualifications:archive', (data) => {
      this.reloadCache()
    })
  }


  async cachedList() {
    if (!this._alreadyLoaded) {
      await qualificationsCache.deleteAllCache()
      this._alreadyLoaded = true
    }

    if(await qualificationsCache.cachePresent()) {
      return await qualificationsCache.get()
    } else {
      await this.reloadCache()
      return await qualificationsCache.get()
    }
  }

  async reloadCache() {
    const results = await this.list(1, 1000)
    await qualificationsCache.cache({ qualifications: results.rows })
  }

  list(page, rowPerPage, filters) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/registries/qualifications/list", {
          page: page,
          rowPerPage: rowPerPage,
          filters: filters
        })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          }
        })
        .catch((err) => {
          reject("error during the call");
        });
    });
  }

  archive(qualification) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!qualification.id)
        reject('id undefined')
      
      axios.post(apiUrl + 'registries/qualifications/archive', qualification).then((response) => {
        if (!response.success)
          reject(response.results)
        else
          resolve(response.results)
      }).catch((error) => {
        reject(error)
      })
    })
  }
  
  create(qualification) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/registries/qualifications/create", qualification)
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          }
        })
        .catch((err) => {
          reject("error during the call");
        });
    });
  }

  update(qualification) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/registries/qualifications/update", qualification)
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          }
        })
        .catch((err) => {
          reject("error during the call");
        });
    });
  }

  delete(qualification) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      if (!qualification.id) {
        reject("id undefined");
      }

      axios
        .get(apiUrl + "/registries/qualifications/delete", {
          id: qualification.id,
        })
        .then((response) => {
          if (!response.success) {
            reject(response.results);
          } else {
            resolve(response);
          }
        });
    });
  }

  get(qualificationId) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl + "/registries/qualifications/get", { id: qualificationId })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          }
        });
    });
  }

  fields() {
    return Promise.resolve([
      // here the fields
      { text: "Nome", value: "name" },
      { text: "Descrizione", value: "description" },
    ]);
  }

}

export default new QualificationsService();
